
import { Action, Getter } from "vuex-class";
import { Component, Ref, Watch, Mixins } from "vue-property-decorator";
import { FormBase, FormInput, FormError, FormSelect, FormSubmit, FormPhone, FormFile } from "@/components/forms";
import { AccountPatchPayload } from "@/store/modules/account.store";
import { HasFormErrors } from "@/mixins/has-form-errors";
import { TeamFunctions } from "@/store/modules/activity-account.store";

@Component({
    components: {
        FormBase,
        FormInput,
        FormSelect,
        FormPhone,
        FormError,
        FormSubmit,
        FormFile,
        Spinner: () => import("@/components/general/spinner.vue"),
    },
})
export default class PageAccountPersonalInfo extends Mixins(HasFormErrors) {
    @Getter("auth/me") me!: CurrentAccount;

    @Action("account/patch") patchAccount!: (payload: AccountPatchPayload) => Promise<Account>;

    @Ref() form!: FormClass;

    loaded: boolean = false;
    updatesLoading: boolean = false;
    profileImage: Media | null = null;
    uploadingFile = false;
    signature: Media | null = null;
    uploadingSignature: string | false = false;

    payload: any = {
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        whise_id: null,
        locale: "",
        function_title: null,
        sign_function: "",
        biv_number: "",
        signature: [],
    };

    function_titles = Object.values(TeamFunctions).map((f: TeamFunctions) => {
        return { value: f, label: this.$t(`views.account.functions.${f.toUpperCase()}`) };
    });

    offices = [
        {
            value: 1,
            label: "Topo Immo",
        },
    ];

    mounted() {

        this.payload.first_name = this.me.first_name;
        this.payload.last_name = this.me.last_name ?? "";
        this.payload.email = this.me.email;
        this.payload.phone = this.me.phone;
        this.payload.sign_function = this.me.sign_function ?? "";
        this.payload.biv_number = this.me.biv_number ?? "";
        this.payload.whise_id = this.me.whise_id;
        if (this.me.media) {
            this.profileImage = this.me.media;
        }
        if (this.me.signature) {
            this.signature = this.me.signature;
        }
        // @ts-ignore
        this.payload.function_title = this.me.function_title;
        this.payload.locale = this.me.locale;
        this.loaded = true;
    }

    async submit(form: FormClass) {
        try {
            this.updatesLoading = true;

            // @ts-ignore
            await this.patchAccount({ ...this.payload, id: this.me.id });

            this.updatesLoading = false;

            this.$toast.open({ message: this.$t("views.settings.admin-users.update_success") as string, type: "success", position: "bottom-right" });
        } catch (e) {
            this.errorResponse = this.formatErrors(e);
        } finally {
            form.reset();
        }
    }

    handleFileUploaded(media: Media | null) {
        this.uploadingFile = false;

        if (media && this.me) {
            this.profileImage = media;
            // @ts-ignore
            this.patchAccount({
                id: Number(this.me.id),
                media: [
                    {
                        uuid: this.profileImage.uuid,
                        name: this.profileImage.name,
                    },
                ],
            });
        }
    }

    handleSignatureUploaded(media: Media | null, name: "signature") {
        this.uploadingSignature = name;

        if (media && name === "signature") {
            this.signature = media;
        }
    }

    @Watch("media", { deep: true })
    handleMediaChanged() {
        this.uploadingFile = false;
        this.uploadingSignature = false;
    }

    @Watch("signature", { deep: true })
    handleSignatureChanged() {
        if (this.payload && this.signature) {
            if (this.signature) {
                this.payload.signature = [
                    {
                        uuid: this.signature.uuid,
                        name: this.signature.name,
                    },
                ];
            }
        }
    }
}
